<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="getStockInReportByItem"
    ></select-shop>

    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <h2 id="stockInTitle" class="col-md-10 col-sm-9 col-8 font-weight-normal">
              {{ $t('report.stockInReport') }}
            </h2>
            <div
              v-if="isExport"
              class="col-md-2 col-sm-3 col-4 text-right text-success"
            >
              <CButton id="stockInExportButton" v-on:click="exportStockInReport()" block color="info">
                <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
              </CButton>
            </div>
          </div>
          <show-date></show-date>
          <CCard class="sort">
            <CCardBody style="padding: 10px; padding-right: 16px;">
              <CRow class="btn-group btn-group-toggle row">
                <span class="p-1 font-weight-normal col-2 col-md-1 col-sm-1 col-xl-1 text-right" style="margin-top: 8px;">{{ $t('search') }}</span>
                <CCol class="col-10 p-1 col-md-4 col-sm-5 col-xl-2">
                  <select id="stockInSearchSelectType" v-model="searchAttr" class="custom-select">
                    <option id="stockInSearchSelectTypeAll" value="">{{ $t('allProduct') }}</option>
                    <option id="stockInSearchSelectTypePLUCode" value="PLUCode">{{ $t('pluCode') }}</option>
                    <option id="stockInSearchSelectTypename" value="name">{{ $t('productName') }}</option>
                  </select>
                </CCol>
                <CCol class="p-1 pr-2 col-12 col-md-4 col-sm-6 col-xl-7">
                  <input id="stockInSearchAttr"  v-if="searchAttr === ''" class="form-control" :placeholder="$t('dialogSelectCategory')" disabled />
                  <input
                    id="stockInSearchkeyword"
                    v-else
                    v-model="keyword"
                    type="text"
                    class="form-control"
                    :placeholder="$t('keyword')"
                  />
                </CCol>
                <CCol class="col-12 col-md-3 col-sm-12 p-1 col-xl-2 text-right">
                  <CButton id="stockInSearchButton" color="success" block @click="getStockInReportByItem()">
                    {{ $t('search') }}
                  </CButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
          <CRow>
            <CCol sm="12" lg="12">
              <sortdata
                v-on:getSort="setSort"
                :sort_value="sort_value"
                :sortdata="sortdata"
              >
            </sortdata>
              <hr />
              <CDataTable
                id="stockInDatatable"
                style="white-space: nowrap;"
                :items="groupByItems"
                :fields="groupByItemFields"
                :loading="loading"
                hover
                border
              >
                <template #unit="{item}">
                  <td style="vertical-align: middle;" class="text-dark">
                    {{ item.unit }} / {{ item.SKURatio }}
                  </td>
                </template>
                <template #no-items-view>
                  <div class="text-center my-5">
                    <h4 style="color: #ced2d8;">
                      {{ $t('noItem') }}
                    </h4>
                  </div>
                </template>
                <template #discount="{ item }">
                  <td v-if="item.discount < 0.00" class="text-right font-weight-normal text-danger">{{ item.discount }}</td>
                  <td v-else class="text-right font-weight-normal text-dark">{{ item.discount }}</td>
                </template>
                <template #discountIn="{ item }">
                  <td v-if="item.discountIn > 0.00" class="text-right font-weight-normal text-danger">-{{ item.discountIn }}</td>
                  <td v-else class="text-right font-weight-normal text-dark">{{ item.discountIn }}</td>
                </template>
              </CDataTable>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getStockInReportByItem">
          </pagination>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import util from '@/util/util'
import Pagination from '@/containers/Pagination'
import Sortdata from '@/containers/SortData'
import permis from '@/util/permission'

export default {
  components: {
    Pagination,
    Sortdata,
  },
  data() {
    return {
      data: [],
      dataByItem: [],
      sortdata: 'name',
      loadingButton: true,
      meta_data: {
        last_page: null,
        current_page: 1,
        items: 0,
        limit: 50,
        itemCount: null,
      },
      sort_value: {
        name: 'name',
        updated_at: 'updated_at',
        sortByNumMin: 'sortByNumMin',
        sortByNumMax: 'sortByNumMax',
        onhandQtyMin: 'onhandQtyMin',
        onhandQtyMax: 'onhandQtyMax',
      },
      keyword: '',
      searchAttr: '',
      members: [],
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users']),
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    isPermission() {
      return permis.findPermissionRead('report', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('report', this.$route.path)
    },
    sortAttr: {
      get() {
        let sortdata = this.sortdata
        if (sortdata === 'sortByNumMin' || sortdata === 'sortByNumMax') {
          sortdata = 'items.cost'
        }

        if (sortdata === 'onhandQtyMin' || sortdata === 'onhandQtyMax') {
          sortdata = 'items.quantity'
        }
        return sortdata
      },
      set(newValue) {
        return newValue
      },
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        { key: 'date', label: this.$i18n.t('date'), _classes: 'text-dark font-weight-normal'},
        { key: 'documentNo', label: this.$i18n.t('documentNo'), _classes: 'text-dark font-weight-normal' },
        { key: 'billNo', label: this.$i18n.t('billNo'), _classes: 'text-dark font-weight-normal' },
        { key: 'PLUCode', label: this.$i18n.t('pluCode'), _classes: 'text-dark font-weight-normal' },
        { key: 'name', label: this.$i18n.t('productName'), _classes: 'text-dark font-weight-normal' },
        { key: 'unit', label: this.$i18n.t('unit'), _classes: 'text-dark font-weight-normal' },
        {
          key: 'quantity',
          label: this.$i18n.t('quantity'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'cost',
          label: this.$i18n.t('priceUnit'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'discount',
          label: this.$i18n.t('discount'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'total',
          label: this.$i18n.t('totalAmount'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'status',
          label: this.$i18n.t('status'),
          _classes: 'text-right font-weight-normal text-dark',
        },
      ]
    },
    items() {
      const data = this.data || []
      let detail = []
      for (let i = 0; i < data.length; i++) {
        const item = data[i]

        let status = ''
        let classes = ''
        
        const productPLU = item.items.productPLU || {}
        const PLUCode = productPLU.PLUCode || ''
        
        const unit = productPLU.unit || {}
        const unitName = unit.name || ''
        const SKURatio = productPLU.SKURatio || ''

        if (item.items.deleted_at != null) {
          classes = 'text-danger'
          status = 'ยกเลิก'
        } else {
          classes = 'text-black'
          status = 'ใช้งาน'
        }

        let discount = item.items.discount
        if (discount > 0) {
          discount *= -1
        }
        detail.push({
          id: i + 1,
          PLUCode: PLUCode,
          name: item.items.productPLU.name,
          documentNo: item.stockNo,
          billNo: item.billNo || '',
          date: moment(String(item.created_at)).format(
            'DD MMMM YYYY HH:mm:ss'
          ),
          unit: unitName,
          cost: util.convertCurrency(item.items.cost),
          quantity: item.items.quantity,
          discount: util.convertCurrency(discount),
          total: util.convertCurrency(item.items.total),
          _classes: classes,
          status: status,
          SKURatio: SKURatio,
        })
      }
      return detail
    },
    groupByItemFields() {
      return [
        { key: 'index', label: '#', _classes: 'text-center text-dark font-weight-normal', _style: 'width:5%',},
        { key: 'PLUCode', label: this.$i18n.t('pluCode'), _classes: 'text-dark font-weight-normal' },
        { key: 'name', label: this.$i18n.t('productName'), _classes: 'text-dark font-weight-normal' },
        { key: 'unit', label: this.$i18n.t('unit'), _classes: 'text-dark font-weight-normal' },
        {
          key: 'documentIn',
          label: this.$i18n.t('documentQty'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'stockIn',
          label: this.$i18n.t('stockInQty'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'discountIn',
          label: this.$i18n.t('totalDiscount'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'totalIn',
          label: this.$i18n.t('totalStockIn'),
          _classes: 'text-right font-weight-normal text-dark',
        },
      ]
    },
    groupByItems() {
      const data = this.dataByItem || []
      let detail = []
      for (let i = 0; i < data.length; i++) {
        const item = data[i]

        const productPLU = item.productPLU || {}
        const PLUCode = item.PLUCode || ''
        
        const unit = productPLU.unit || {}
        const unitName = unit.name || ''
        const SKURatio = productPLU.SKURatio || ''

        detail.push({
          index: i + 1,
          PLUCode: PLUCode,
          name: productPLU.name || '', 
          unit: unitName,
          SKURatio: SKURatio,
          documentIn: item.documentIn || 0,
          stockIn: item.stockIn || 0,
          discountIn: util.convertCurrency(item.discountIn || 0),
          totalIn: util.convertCurrency(item.totalIn || 0),
        })
      }
      return detail
    },
  },
  created() {
    // this.getStockInReport()
    this.getStockInReportByItem()
  },
  methods: {
    ...util,
    setSort(value) {
      this.sortdata = value
      // this.getStockInReport()
      this.getStockInReportByItem()
    },
    getStockInReport(page = 1) {
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')
      let sortOrder = ''
      let sort = ''
      if (this.sortdata === 'name') {
        sort = 'items.name'
      } else if (
        this.sortdata === 'sortByNumMin' ||
        this.sortdata === 'sortByNumMax'
      ) {
        sort = 'items.total'
      } else if (
        this.sortdata === 'onhandQtyMin' ||
        this.sortdata === 'onhandQtyMax'
      ) {
        sort = 'items.quantity'
      } else {
        sort = this.sortdata
      }
      if (
        this.sortdata === 'sortByNumMin' ||
        this.sortdata === 'onhandQtyMin'
      ) {
        sortOrder = 'asc'
      } else if (
        this.sortdata === 'sortByNumMax' ||
        this.sortdata === 'onhandQtyMax' ||
        this.sortdata === 'updated_at'
      ) {
        sortOrder = 'desc'
      } else {
        sortOrder = 'asc'
      }
      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        page: page,
        limit: this.meta_data.limit || 50,
        stockType: 1,
        searchVal: this.keyword,
        searchAttr: this.searchAttr,
        sortAttr: sort,
        sortOrder: sortOrder,
      }
      this.loading = true

      axios
        .get('/inventory/v1.0/stockdocument/' + uid + '/items', { params })
        .then((res) => {
          this.data = res.data.data
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          
          this.loading = false
          this.loadingButton = true

        })
        .catch((error) => {
          console.log(error)
        })
    },
    getStockInReportByItem(page = 1) {
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')
      let sortOrder = ''
      let sort = ''
      if (this.sortdata === 'name') {
        sort = 'name'
      } else if (
        this.sortdata === 'sortByNumMin' ||
        this.sortdata === 'sortByNumMax'
      ) {
        sort = 'totalIn'
      } else if (
        this.sortdata === 'onhandQtyMin' ||
        this.sortdata === 'onhandQtyMax'
      ) {
        sort = 'stockIn'
      } else {
        sort = this.sortdata
      }
      if (
        this.sortdata === 'sortByNumMin' ||
        this.sortdata === 'onhandQtyMin'
      ) {
        sortOrder = 'asc'
      } else if (
        this.sortdata === 'sortByNumMax' ||
        this.sortdata === 'onhandQtyMax' ||
        this.sortdata === 'updated_at'
      ) {
        sortOrder = 'desc'
      } else {
        sortOrder = 'asc'
      }
      let params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        page: page,
        limit: this.meta_data.limit,
        stockType: 1,
        sortAttr: sort,
        sortOrder: sortOrder,
      }
      if (this.keyword !== '' && this.searchAttr !== '') {
        params['searchVal'] = this.keyword
        params['searchAttr'] = this.searchAttr
      }

      this.loading = true

      axios
        .get('/inventory/v1.0/stockdocument/' + uid + '/byitems', { params })
        .then((res) => {
          const data = res.data
          this.dataByItem = data.data
          
          // Pagination.
          const paginate = data.paginate || {}
          this.meta_data.items = paginate.items || 0
          this.meta_data.last_page = paginate.pageCount || 0
          this.meta_data.current_page = paginate.currentPage || 0
          this.meta_data.itemCount = paginate.itemCount || 0
          this.meta_data.limit = paginate.perPage || 0

          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportStockInReport() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')
      let sortOrder = ''
      let sort = ''
      if (this.sortdata === 'name') {
        sort = 'items.name'
      } else if (
        this.sortdata === 'sortByNumMin' ||
        this.sortdata === 'sortByNumMax'
      ) {
        sort = 'items.total'
      } else if (
        this.sortdata === 'onhandQtyMin' ||
        this.sortdata === 'onhandQtyMax'
      ) {
        sort = 'items.quantity'
      } else {
        sort = this.sortdata
      }
      if (
        this.sortdata === 'sortByNumMin' ||
        this.sortdata === 'onhandQtyMin'
      ) {
        sortOrder = 'asc'
      } else if (
        this.sortdata === 'sortByNumMax' ||
        this.sortdata === 'onhandQtyMax' ||
        this.sortdata === 'updated_at'
      ) {
        sortOrder = 'desc'
      } else {
        sortOrder = 'asc'
      }
      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        page: 1,
        limit: 20,
        stockType: 1,
        searchVal: this.keyword,
        searchAttr: this.searchAttr,
        sortAttr: sort,
        sortOrder: sortOrder,
      }
      report({
        url: '/inventory/v1.0/stockdocument/' + uid + '/byitems/excel',
        params: params,
        method: 'GET',
        responseType: 'blob',
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'รายงานรับสินค้าเข้าแสดงรายการ.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="../../../src/assets/styles/sortCss.css"></style>